/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js
 * - /npm/simple-jekyll-search@1.10.0/dest/simple-jekyll-search.min.js
 * - /npm/lazysizes@5.3.2/lazysizes.min.js
 * - /npm/dayjs@1.11.9/dayjs.min.js
 * - /npm/dayjs@1.11.9/locale/tr.min.js
 * - /npm/dayjs@1.11.9/plugin/relativeTime.min.js
 * - /npm/dayjs@1.11.9/plugin/localizedFormat.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
